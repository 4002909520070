import { useCallback, useLayoutEffect } from 'react'
import validator from 'validator'
import axios from 'axios'
import qs from 'query-string'

const validateForm = () => {
  const email = document.getElementById('form-email').value
  const name = document.getElementById('form-name').value
  const botfield = document.getElementById('form-honeypot').value
  const message = document.getElementById('form-message').value

  const submitButton = document.querySelector('.inputs > button')

  if (!submitButton) return

  if (!name || !message || !validator.isEmail(email) || botfield !== '')
    submitButton.setAttribute('disabled', 'true')
  else submitButton.removeAttribute('disabled')
}

const handleSubmit = async (e) => {
  e.preventDefault()

  const email = document.getElementById('form-email').value
  const name = document.getElementById('form-name').value
  const botfield = document.getElementById('form-honeypot').value
  const message = document.getElementById('form-message').value

  if (botfield !== '') return

  try {
    const formData = {
      'form-name': 'contact-us',
      'bot-field': botfield,
      name: name,
      email: email,
      message: message,
    }

    const axiosOptions = {
      url: '/',
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(formData),
    }

    await axios(axiosOptions)

    document.querySelector('p.description').style.display = 'none'
    document.querySelector('p.confirmation-message').style.display = 'block'

    document.querySelector('div.inputs').style.display = 'none'
    document.querySelector('div.asset-download').style.display = 'block'

    // eslint-disable-next-line no-empty
  } catch {}
}

const useInlineFormInteractions = (pageUrl) => {
  const handleSubmitCallback = useCallback((e) => handleSubmit(e, pageUrl), [
    pageUrl,
  ])

  useLayoutEffect(() => {
    const containerWrapper = document.querySelector(
      '.inline-form-container-wrapper'
    )

    if (!containerWrapper) return

    containerWrapper.addEventListener('submit', handleSubmitCallback)
    containerWrapper.addEventListener('keydown', validateForm)
    containerWrapper.addEventListener('change', validateForm)
    validateForm()

    return () => {
      document.removeEventListener('keydown', validateForm)
      document.removeEventListener('submit', handleSubmitCallback)
      containerWrapper.removeEventListener('change', validateForm)
    }
  }, [])
}

export default useInlineFormInteractions
