import styled from 'styled-components'
import Container from 'components/Container'
import media from 'styles/media'
import Tagline from 'components/Tagline'
import { color } from 'const/colors'

export const PartnerTagline = styled(Tagline)`
  margin-bottom: 75px;

  ${media.phone`
    margin-bottom: 0px;
  `}
`

const tabletText = `
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  `

export const BodyText = styled.div`
  color: ${color.black.o100};
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 120px;

  ${media.tablet`${tabletText}`}

  figure img {
    max-width: 140%;
    margin-left: 50%;
    transform: translateX(-50%);

    ${media.phone`
      width: 110%;
      position: relative;
    `}
  }

  figure {
    margin: 100px 0 90px;

    ${media.phone`
      margin: 50px 0 40px;
    `}
  }

  ${media.phone`
    padding-bottom: 50px;
  `}

  table {
    font-size: 16px;
    width: 100%;
    margin: 0 auto;
    tr {
      display: flex;
      ${media.tablet`
        flex-direction: column;
      `}
    }
    td {
      flex: 1;
      padding: 5px;
    }
  }
  
  h1 {
  line-height: 40px;
  }

  .inline-form-container-wrapper {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 0px -50vw 100px -50vw;
    
    color: ${color.white.o100};
    background-color: ${color.primary.o100};

    .inline-form-container {
      max-width: 700px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 50px 0;
      margin: 0 auto;
      
      ${media.tablet`
        max-width: 480px;
        flex-direction: column;
      `}

      ${media.phone`
        max-width: 312px;
      `}
    }

    .inline-form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow: hidden;
      flex: 1.62;
      padding-right: 3em;
      
      * {
        overflow-wrap: break-word;
      }
      
      p {
        text-align: justify;
      }
    }
    
    .inputs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      
      .text-field {
        box-sizing: border-box;
        border: none;
        outline: none;
        max-width: 465px;
        width: 100%;
        height: 45px;
        margin-bottom: 50px;
        caret-color: ${color.white.o100};
        color: ${color.white.o100};
        background-color: ${color.transparent};
        border-bottom: 1px solid ${color.white.o100};
        border-radius: 0;
        padding: 0;
        font-family: inherit;
        font-size: 24px;
        line-height: 40px;
        transition: background-color 0.2s;
        &::placeholder {
          color: ${color.white.o75};
        }

        ${media.tablet`
          font-size: 20px;
          &::placeholder {
            font-size: 20px;
          }
        `}

        -webkit-box-shadow: 0 0 0px 1000px ${color.primary.o100} inset;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-text-fill-color: ${color.white.o100};
        }
      }
      
      textarea {
        box-sizing: border-box;
        border: none;
        outline: none;
        max-width: 465px;
        width: 100%;
        height: 45px;
        margin-bottom: 25px;
        caret-color: ${color.white.o100};
        background-color: ${color.transparent};
        border-bottom: 1px solid ${color.white.o100};
        border-radius: 0;
        padding: 0;
        font-family: inherit;
        font-size: 24px;
        line-height: 40px;
        color: ${color.white.o100};
        transition: background-color 0.2s;

        &::placeholder {
          color: ${color.white.o75};
        }

        ${media.tablet`
          font-size: 20px;
          &::placeholder {
            font-size: 20px;
          }
        `}
      }
      
      button {
        background-color: ${color.transparent};
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: #fff;
        border: 2px solid ${color.white.o100};
        min-width: 78px;
        padding: 16px 25px;
        cursor: pointer;
        font-family: CeraPro,sans-serif;

        position: relative;
        z-index: 1;
        transition: 0.08s ease-in;
        -o-transition: 0.08s ease-in;
        -ms-transition: 0.08s ease-in;
        -moz-transition: 0.08s ease-in;
        -webkit-transition: 0.08s ease-in;

        &:hover {
          color: ${color.hotPink.o100};
        }

        &::before {
          content: '';
          position: absolute;
          background: ${color.white.o100};
          bottom: 0;
          left: 0;
          right: 100%;
          top: 0;
          z-index: -1;
          -webkit-transition: right 0.09s ease-in;
        }

        &:hover:before {
          right: 0;
        }

        ${media.phone`
          margin-bottom: 50px;
        `}

        &:focus {
          outline: 0;
        }

        &:disabled {
          background-color: ${color.white.o25};
          color: ${color.primary.o100};
          border: 2px solid ${color.transparent};
          cursor: auto;

          &:hover:before {
            right: 100%;
          }
        }
      }
    }

    .border {
      padding: 22px 13px;
      display: flex;

      background-color: ${color.hotPink.o100};

      ${media.phone`
        width: 100%;
        padding: 16px;
        margin: 0;
      `}

      &:focus {
        outline: 0;
      }
    }
  }
  
  a:hover {
    color: rgba(249,69,128,1);
  }
  
`

export const PartnerContainer = styled(Container)`
  margin: 0 400px;

  ${media.tablet`
    max-width: 480px;
    margin: 0 24px;
  `}

  ${media.phone`
    max-width: 312px;
  `}
  ${media.desktop`
    max-width: 580px;
    margin: 0 24px;
   `}
   ${media.desktopLarge`
    max-width: 680px;
    margin: 0 24px;
   `}
`
